<template>
  <b-card no-body>
    <b-card-body
      class="d-flex justify-content-between align-items-center"
      v-if="!is_mobilesize"
    >
      <div class="truncate">
        <p class="mb-25 font-weight-bolder">
          {{ statistic }}
        </p>
        <span>{{ statisticTitle }}</span>
      </div>
      <b-avatar :variant="`light-${color}`" size="45">
        <feather-icon size="21" :icon="icon" />
      </b-avatar>
    </b-card-body>
    <b-card-body
      class="d-flex justify-content-between align-items-between"
      v-else
    >
      <div class="d-flex justify-content-center align-items-center">
        <b-avatar :variant="`light-${color}`" size="45">
          <feather-icon size="21" :icon="icon" />
        </b-avatar>
        <div class="pl-5">
          <p class="mb-25 font-weight-bolder">
            {{ statistic }}
          </p>
          <span>{{ statisticTitle }}</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BAvatar } from "bootstrap-vue";
import { mixinList } from "@/mixins/mixinList";

export default {
  mixins: [mixinList],
  components: {
    BCard,
    BCardBody,
    BAvatar,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
  },
};
</script>
