<template>
  <div>
    <advertisment-section />
    <b-row v-if="loading">
      <b-col
        lg="12"
        class="d-flex justify-content-center mb-3 col-12 text-center"
      >
        <b-spinner variant="primary" />
      </b-col>
    </b-row>

    <b-row v-else>
      <b-col lg="12">
        <h2 class="text-center mt-2 mb-2">Token Data</h2>
      </b-col>

      <b-col lg="3">
        <statistic-card-horizontal
          icon="ActivityIcon"
          :statistic="token_meta_data.symbol || 'N/A'"
          statistic-title="Token Symbol"
        />
      </b-col>
      <b-col lg="3">
        <statistic-card-horizontal
          icon="HashIcon"
          :statistic="token_meta_data.name || 'N/A'"
          statistic-title="Token Name"
        />
      </b-col>
      <b-col lg="3">
        <statistic-card-horizontal
          icon="DollarSignIcon"
          :statistic="
            getRoundedValue(token_meta_data.totalLiquidity, 10) || 'N/A'
          "
          statistic-title="Total Liquidity"
        />
      </b-col>
      <b-col lg="3">
        <statistic-card-horizontal
          icon="BarChart2Icon"
          :statistic="getRoundedValue(token_meta_data.derivedUSD, 18) || 'N/A'"
          statistic-title="Token Price (USD)"
        />
      </b-col>
      <b-row class="text-center">
        <b-col align-self="center">
          <b-alert variant="danger" show class="mb-0">
            <div class="alert-body alert-text">
              <feather-icon icon="InfoIcon" class="mr-50" />
              Warning :<b> Rugscan.finance</b> is a tool to review smart
              contracts. <b>Do not invest based on these results.</b> We are not
              responsible for any losses incurred... Always be careful with your
              money and DYOR.
            </div>
          </b-alert>
        </b-col>
      </b-row>

      <b-col lg="12">
        <h2 class="text-center mt-2 mb-2">Contract Scan Result</h2>
        
        <div class="mb-2">
          <b-alert show variant="primary">
            <div class="alert-body">
              <feather-icon class="mr-25" icon="CodesandboxIcon" />
              <span class="ml-25"
                >Contract Address :
                {{ contract_address ? contract_address : "N/A" }}</span
              >
            </div>
          </b-alert>
        </div>
      </b-col>
      
      <b-col lg="12">
        <b-card class="card-class">
          <b-row
            v-for="(scan_data, index) in scan_data_log"
            :key="index"
            class="pl-1 pr-1"
          >
            <span class="text-success text-darken-2">
              [{{ index + 1 }}] - {{ scan_data.timestamp }} :
              <span v-if="scan_data.log_level != 'info'">
                <span :class="'text-' + scan_data.log_level">{{
                  scan_data.message
                }}</span>
              </span>
              <span v-else>
                <span>{{ scan_data.message }}</span>
              </span>

              <span v-if="scan_data.result" class="text-warning">
                ->
                {{ scan_data.result ? scan_data.result : "" }} points
              </span>
              <span v-else-if="scan_data.result == 0" class="text-warning">
                --> 0 points ( not initiated )
              </span>
            </span>
          </b-row>

          <b-row class="pl-1 pt-3 pr-1 text-center">
            <h5 class="point-class">
              {{ final_verdict.confident_score }} points.
              {{ final_verdict.verdict }}
            </h5>
          </b-row>
        </b-card>
      </b-col>
      <!-- <div class="d-flex justify-content-center col-12 text-center">
        <SocialShare :current_location="current_location" :contract_address_value="contract_address_value" />
      </div> -->
      
      <b-col lg="12" class="mb-2">
        <h2 class="text-center mt-2 mb-2">How we evaluate the contract</h2>
        <app-collapse>
          <app-collapse-item
            title="comparison of from/to/sender/recipient to non-zero section"
          >
            The scammer uses a condition in transferFrom methods to block
            transfers originating from PancakeSwap Liquidity Pool. The address
            of the PancakeSwap LP is set during the very first interaction of
            the pool with the contract via add liquidity at the beginning, so
            the variable newun is set exactly to the address of a Liquidity Pool
            for that token. When the variable is set, swaps back are not working
            as contract blocks transfers from Liquidity pool to the users, which
            results in transferFrom error message in PancakeSwap. The value of
            the coin increases and due to the AMM mechanics the scammer to cash
            out pulls back his LP token with a profit.
          </app-collapse-item>

          <app-collapse-item
            title="Pancake swap swap router : comparison swap interfaces and versions"
          >
            This is where the correct implementation of Swap interface and the
            versions are evaluated.
          </app-collapse-item>

          <app-collapse-item title="Ownership function">
            This is where the ownership renounce and ownership transfer
            functions are evaluated.
          </app-collapse-item>
          <app-collapse-item title="Mint function">
            We evaluate token burn against token mint from the supply and assign
            points based on the risk levels.
          </app-collapse-item>
          <app-collapse-item title="Liquidity locked or unlocked">
            We evaluate liquidity through all DEX platforms to check whether
            it's locked and the locked percentages. The points are assigned
            based on the risk levels of the total locked percentage.
          </app-collapse-item>
          <app-collapse-item title="Top 10 liquidity holders percentage check">
            We check the top 10 liquidity holders and the percentage of
            liquidity that they are holding. The points are assigned based on
            the risk levels of holding liquidity percentages.
          </app-collapse-item>
        </app-collapse>
      </b-col>

      <b-col lg="12">
        <b-card
          text-variant="white"
          bg-variant="primary"
          align="center"
          class="position-static support-community-card"
        >
          <blockquote class="blockquote mb-0">
            <p class="support-community">
              Was this scan helpful? Join the Matador Token community and show
              your support!
            </p>
            <footer class="text-white opacity-75">
              <a href="https://t.me/matadortokenbsc">
                <p class="community-link">Join our community</p>
              </a>
            </footer>
          </blockquote>
        </b-card>
      </b-col>

      <b-col lg="12">
        <h2 class="text-center mt-2 mb-2">Top 10 token holders data</h2>
        <p class="mb-2">
          <i
            >If the top holder has a very high percentage and is a wallet, it
            could be a rug. <br /><br />
            Did they burn 50% of the supply instantly after the initial supply
            was minted? If so, all of the other wallets % holdings are actually
            double what they say in bscscan. Burning a large amount of the
            initial supply before launch is usually a trick to mislead people
            into thinking a 20% holder is a 10% holder. Some projects even shill
            the burn as if it was some sort of sacrifice on their part. Check
            the project website to see if they are transparent about why they
            did this initial burn.

            <br /><br />
            Check that they have locked the LP. For example, the BNB LP of the
            selected token (some tokens may use BUSD or something else as their
            main LP). If the top holder has almost 100% of the supply and is a
            wallet address, the LP has not been locked/burned.
            <br /><br />
            Check that they have locked the LP. For example, this the BNB LP of
            the selected token (some tokens may use BUSD or something else as
            their main LP). If the top holder has almost 100% of the supply and
            is a wallet address, the LP has not been locked/burned.
            <br /><br />If the top holder of LP has the burn address (any
            address starting with 0x0000...), the LP is burned. <br /><br />If
            the top holder of LP is a contract address (one that has this icon
            ), the LP has likely been locked. Ensure that the lock timer is
            longer than at least 2 months for it to be safe. You need the
            project owners to publish the url of the LP lock timer to know how
            long it is locked for (usually dxsale or unicrypt). <br /><br />If
            the token has pools/farms, the top holder will usually be the
            pool/farm contract.
          </i>
        </p>
        <scan-table
          :table-data="changed_table_data"
          :table-name="'holder-log'"
        />
      </b-col>
    </b-row>
    <br />
    <advertisment-section />
  </div>
</template>

<script>
import { BRow, BCol, BSpinner, BCard, BAlert } from "bootstrap-vue";
import ScanTable from "./ScanTable.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import AdvertismentSection from "@core/layouts/components/AdvertismentSection.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { mixinList } from "@/mixins/mixinList";
// import SocialShare from "./SocialShare.vue";

export default {
  mixins: [mixinList],
  components: {
    BRow,
    BCol,
    ScanTable,
    BSpinner,
    BCard,
    BAlert,
    AppCollapse,
    AppCollapseItem,
    StatisticCardHorizontal,
    AdvertismentSection,
    // SocialShare
  },
  data() {
    return {
      changed_table_data: [],
      current_location: '',
      contract_address_value: ''
    };
  },
  computed: {
    scan_data_log() {
      return this.$store.state.scan.scan_data_log;
    },
    holders_data() {
      return this.$store.state.scan.holders_data;
    },
    loading() {
      return this.$store.state.loaders.loading;
    },
    final_verdict() {
      return this.$store.state.scan.final_verdict;
    },
    token_meta_data() {
      return this.$store.state.scan.token_data;
    },
    contract_address() {
      return this.$store.state.scan.contract_address;
    },
  },
  watch: {
    holders_data(val) {
      this.setDataToTabale(val);
    },
  },
  created() {
    this.scanData();
    this.current_location = window.location.href;
    this.contract_address_value = this.$router.currentRoute.params.id;
  },
  methods: {
    scanData() {

      //get the logged in user id
      const userData = localStorage.getItem('userData');
      const userObject = userData ? JSON.parse(userData) : null;
      const address = {
        contract_address: this.$router.currentRoute.params.id,
        came_from: this.$route.params.came_from || null,
        user_id: userObject ? userObject.id : null
      }; 
      this.$store.dispatch("FETCH_SCAN_DATA", address);
    },
    setDataToTabale() {
      // this.changed_table_data = [];
      if (this.holders_data.length > 10) {
        for (let index = 0; index < 10; index++) {
          this.changed_table_data.push(this.holders_data[index]);
        }
      } else {
        this.holders_data.forEach((element) => {
          this.changed_table_data.push(element);
        });
      }
    },
    getRoundedValue(value, fixPoint) {
      let number = parseFloat(value);
      return number.toFixed(fixPoint);
    },
  },
};
</script>
<style>
.card-class {
  background-color: #303030;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
}
.header-class {
  /* rgb(65,165,238) */
  color: #2575fc;
}
.message-class {
  color: rgb(224, 149, 247);
}
.message-result {
  color: rgb(116, 217, 247);
}
.point-class {
  color: yellow;
}
.item-class {
  background-color: #4b4a4a;
}
.community-link {
  font-size: 1rem;
  font-style: italic;
  color: aliceblue;
  text-decoration: underline;
}
.support-community {
  font-size: 13px;
}
.support-community-card {
  padding: 0px !important;
}
@media (max-width: 1024px) {
  .alert-text {
    font-size: 7px;
  }
  .card-body {
    padding: 10px 70px 0px 40px;
    font-size: 12px;
  }
}
</style>
